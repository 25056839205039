/**
 * The `formatNumberIndianStyle` function formats a number in Indian style by adding commas as
 * thousands separators and displaying decimal places if present.
 * @param number - The number parameter is the number that you want to format in the Indian style.
 * @returns a formatted string representation of the input number in Indian style.
 */
export function formatNumberIndianStyle(number) {
    var x = number?.toString();
    var afterPoint = '';
    if (x?.indexOf('.') > 0)
        afterPoint = x?.substring(x?.indexOf('.'), x?.length);
    x = Math.floor(number);
    x = x.toString();
    var lastThree = x?.substring(x.length - 3);
    var otherNumbers = x?.substring(0, x.length - 3);
    if (otherNumbers != '')
        lastThree = ',' + lastThree;
    var res = otherNumbers?.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint;
    return res;
};

/***Rating Description */

export const ratingDescriptions = {
    1: "Terrible",
    2: "Bad",
    3: "Okay",
    4: "Good",
    5: "Great",
};


/**
 * The `openIndexedDB` function is a JavaScript function that opens an IndexedDB database named
 * "E9Database" and creates an object store named "videos" with a key path of "id" if the database is
 * being upgraded.
 * @returns The `openIndexedDB` function returns a promise that resolves to the indexedDB database
 * object.
 */
export const openIndexedDB = async () => {
    return new Promise((resolve, reject) => {
      const request = indexedDB.open("E9Database", 1);
  
      request.onerror = (event) => {
        reject("Database error: " + event.target.errorCode);
      };
  
      request.onsuccess = (event) => {
        resolve(event.target.result);
      };
  
      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        db.createObjectStore("videos", { keyPath: "id" });
      };
    });
  };


  /**
   * The `formatDate` function takes a date string as input and returns a formatted date string in the
   * format "dayth Month year".
   * @param dateStr - The `dateStr` parameter is a string representing a date in a specific format.
   * @returns The function `formatDate` returns a formatted date string in the format "dayth Month
   * year".
   */
  export function formatDate(dateStr) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  
    const date = new Date(dateStr);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
  
    const suffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
      }
    }
  
    return `${day}${suffix(day)} ${month} ${year}`;
  }


/* The code is exporting two constants and a function. */
 export  const EARTH_RADIUS = 6371;

 export const degreesToRadians = (degrees) => {
    return degrees * Math.PI / 180;
  };


/**
 * The function `clearIndexedDBData` clears all data from an IndexedDB object store named "videos".
 */
 export  const clearIndexedDBData = async () => {
    try {
      const db = await openIndexedDB(); // Make sure this function opens the correct DB
      const transaction = db.transaction(["videos"], "readwrite"); // Adjust "videos" if your object store has a different name
      const store = transaction.objectStore("videos");
  
      // Clear all data from the store
      const clearRequest = store.clear();
  
      clearRequest.onsuccess = () => {
        // Optionally, update any state or UI here
      };
  
      clearRequest.onerror = (event) => {
        console.error("Error clearing IndexedDB data:", event.target.error);
      };
    } catch (error) {
      console.error("Error accessing IndexedDB:", error);
    }
  };

/**
 * The function `getDaysDifference` calculates the number of days between the current date and a given
 * updated date.
 * @param updatedDate - The `updatedDate` parameter is a string representing a date in a specific
 * format.
 * @returns The function `getDaysDifference` returns the number of days between the current date and
 * the provided `updatedDate`.
 */
  export const getDaysDifference = (updatedDate) => {
    const currentDate = new Date();
    const updated = new Date(updatedDate);
    const differenceInTime = currentDate.getTime() - updated.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return Math.floor(differenceInDays);
  };
  