import { Button, Flex, Grid, GridItem } from "@chakra-ui/react";
import { ImageCarousel } from "../../components/ImageCarousel/ImageCarousel";
import { V5Navbar } from "../../components/V5Navbar/V5Navbar";
import V5AdvertisementCarousel from "../../../website/Layout/V5AdvertisementCarousel";
const SlideData = [
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/jcb-7years.jpg`,
    link: "https://www.jcb.com/en-in/campaigns/2023/enquiry",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/my-crane%24%24in.my-crane.com.png`,
    link: "https://in.my-crane.com/",
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/www.equip9.com%24%24ad-4.png`,
  },
  {
    url: `${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/www.equip9.com$$ad-5.png`,
  },
];

export const V5AdDividerLayout = ({ onSubmitClick = () => { }, isDisabled, buttonText, hasFooter, children }) => {
  return (
    <Grid minH={["100vh", "100vh","100vh", ""]} templateColumns={["1fr", "1fr", "1fr", "repeat(2, 1fr)"]}>
      <GridItem 
      // maxH={"100%"} 
      display="grid"
      gridAutoFlow="row"
      height="100%">
        <div><V5Navbar /></div>
        <div>{children}</div>

        {hasFooter ? (
               <Flex justifyContent="center" alignItems="center"  >
                  <Button
                    variant="gradient"
                    fontWeight="semibold"
                    width="80%"
                    margin="0 auto"
                    maxW="347px"
                    onClick={(e) => onSubmitClick(e)}
                    isDisabled=
                    {isDisabled}>
                    {buttonText ? buttonText : "NA"}
                  </Button>
                  </Flex>
        ) : null}

      </GridItem>

      <GridItem display={["none", "none", "none", "unset"]}>
        <V5AdvertisementCarousel
          maxWidth="50vw"
          maxHeight="100vh"
          dataArray={SlideData}
          speed={5000}
        />
      </GridItem>
    </Grid>
  );
};
