  import "./MarketPlaceSteps1.css";
  import React, { useState, useEffect } from "react";
  import { Formik, Form, Field, ErrorMessage } from "formik";
  import * as Yup from "yup";
  import { ReactSVG } from "react-svg";
  import { useQuery, useQueryClient } from "react-query";
  import { useDispatch, useSelector } from "react-redux";
  import { useParams, useLocation } from "react-router-dom";
  import { useToast } from "@chakra-ui/react";
  import { useNavigate } from "react-router-dom";
import { increaseStepperIndex } from "./slice/postMaterialSlice";
import { LocationInput } from "../../../../LocationInput/LocationInput";
import { getEquipmentMakesById, getEquipmentModelById, getEquipmentTypeList } from "../../../../../services/equipments/equipments";
import { postMaterialDetails } from "../../../../../services/materials/materials";


  const validationSchema = Yup.object().shape({
    productName: Yup.string().required("Product Name is required").min(3,'Minumum 3 characters required').max(100,'Number of Characters exceeding'),
    productnumber: Yup.string().required("Product Number is required").min(3,'Minumum 3 characters required').max(25,'Number of Characters exceeding'),
    pricerangeminimum: Yup.string().required("Minimum is required"),
    pricerangemaximum: Yup.string().required("Maximum is required"),
    pricerangeminimum: Yup.number()
      .required("Minimum price is required")
      .positive("Minimum price must be a positive number")
      .max(9999999, 'Maximum 7 digits allowed')
      .test(
        'is-less',
        'Minimum price should be less than maximum price',
        function(value) {
          const { pricerangemaximum } = this.parent;
          return value < pricerangemaximum;
        }
      ),
    pricerangemaximum: Yup.number()
      .required("Maximum price is required")
      .positive("Maximum price must be a positive number")
      .max(9999999, 'Maximum 7 digits allowed')
      .test(
        'is-greater',
        'Maximum price should be greater than minimum price',
        function(value) {
          const { pricerangeminimum } = this.parent;
          return value > pricerangeminimum;
        }
      ),
      equipmentTypeSelection: Yup.string()
      .required("Equipment type selection is required")
      .oneOf(["specific", "any"], "Invalid equipment type selection"),
      isOriginalProduct: Yup.number().nullable()
      .oneOf([0, 1], "Select Yes or No for Original Product")
      .required("Select Yes or No for Original Product"),
      
  });
  export const MarketPlacestep1 = () => {
    const { MaterialTypeStperId } = useParams();
    const userData = useSelector((state) => state.user);
    const [selectedType, setSelectedType] = useState(null);
    const [selectedTypeOEM, setSelectedTypeOEM] = useState(null);
    const [selectedTypeModel, setSelectedTypeModel] = useState(null);
    const [EquipmentList, setEquipmentList] = useState(null);
    const [EquipmentMake, setEquipmentMake] = useState(null);
    const [EquipmentModel, setEquipmentModel] = useState(null);
    const [SelectedEquipmentType, setSelectedEquipmentType] = useState(null);
    const [SelectedEquipmentModel, setSelectedEquipmentModel] = useState(null);
    const [SelectedEquipmentmake, setSelectedEquipmentmake] = useState(null);
    const [isOriginalProduct, setisOriginalProduct] = useState(null);
    const [anyOEM, setanyOEM] = useState(0);
    const [anyEquipment, setanyEquipment] = useState(0);
    const [anyModel, setanyModel] = useState(0);
    const [NavigateMaterialType,setNavigateMaterialType]=useState(null);
    const [equipmentTypeSelection, setEquipmentTypeSelection] = useState("");
    const [equipmentOEMSelection, setEquipmentOEMSelection] = useState("");
    const [equipmentModelSelection, setEquipmentModelSelection] = useState("");

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const toast = useToast();
    const location=useLocation();
    // console.log("location",location)

    const handleSelectType = (type) => {
      // console.log(type);
      setSelectedType(type);
      setEquipmentTypeSelection(type);
      if (type === "specific") {
        setanyEquipment(1);
      }
    };

    const handleSelectTypeOEM = (type) => {
      setSelectedTypeOEM(type);
      if (type === "specificOEM") {
        setanyOEM(1);
      }
    };

    const handleSelectTypeModel = (type) => {
      setSelectedTypeModel(type);
      if (type === "specificModel") {
        setanyModel(1);
      }
    };

    const handleOriginalProductYes = () => setisOriginalProduct(1);
  const handleOriginalProductNo = () => setisOriginalProduct(0);



    useEffect(() => {
      const fetchEquipmentList = async () => {
          try {
              const data = await getEquipmentTypeList();
              setEquipmentList(data?.Data?.EquipmentsList);
          } catch (error) {
              console.error('Error fetching equipment list', error);
              // Handle error
          }
      };

      fetchEquipmentList();
  }, [ ]);


  useEffect(() => {
    if (SelectedEquipmentType) {
        const fetchEquipmentMake = async () => {
            try {
                const data = await getEquipmentMakesById(SelectedEquipmentType);
                setEquipmentMake(data?.Data?.OEMList);
            } catch (error) {
                console.error('Error fetching equipment make', error);
              }
            };
    
            fetchEquipmentMake();
        } else {
            setEquipmentMake(null);
        }
    }, [SelectedEquipmentType]);



    useEffect(() => {
      if (SelectedEquipmentModel) {
          const fetchEquipmentModel = async () => {
              try {
                  const data = await getEquipmentModelById(SelectedEquipmentModel);
                  setEquipmentModel(data?.Data?.ModelList);
              } catch (error) {
                  console.error('Error fetching equipment model', error);
                  // Handle error
              }
          };
  
          fetchEquipmentModel();
      } else {
          setEquipmentModel(null);
      }
  }, [ SelectedEquipmentModel]);
    

    // console.log({isOriginalProduct});


    const equipmentTypeLayoutStyle = SelectedEquipmentType
    ? { width: 'auto', minWidth: '500px', display:"flex", flexDirection: "row" } // Adjust to auto when specific equipment is selected
    : { width: '500px' };

    /**********************handle Submit  */
    const handleSubmit = async (values) => {
      const payload = {
        MaterialTypeId: parseInt(localStorage.getItem('MaterialTypeId')),
        ProductName: values.productName,
        ProductNumber: values.productnumber,
        MinPriceRange: parseInt(values.pricerangeminimum),
        MaxPriceRange: parseInt(values.pricerangemaximum),
        Location: values._jobLocation.name,
        AnyEquipment: anyEquipment,
        EquipmentTypeId: SelectedEquipmentType,
        AnyOEM: anyOEM,
        AnyModel: anyModel,
        OEMId: SelectedEquipmentModel,
        ModelId: SelectedEquipmentmake,
        IsOriginal:isOriginalProduct,
        Latitude: values._jobLocation.lat,
        Longitude: values._jobLocation.lng,
      };
      //debugger;
      //debugger;
      try {
        const data = await postMaterialDetails( payload);
        if (data.Status === 201) {
          setNavigateMaterialType(data?.Data?.LastId)
          const LastId = data?.Data?.LastId;
          toast({
            title: "",
            description: `Material Added Successfully`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top-right",
          });
          dispatch(increaseStepperIndex());
          localStorage.setItem('LastId', LastId); 
          // localStorage.setItem('Material')
       
        }
      } catch (err) {
        toast({
          title: "",
          description: `Error`,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
      }
    };
    return (
      <Formik
        initialValues={{
          productName: "",
          productnumber: "",
          pricerangeminimum: "",
          pricerangemaximum: "",
          _jobLocation: "",
          specificEquipment: "",
          equipmentTypeSelection: equipmentTypeSelection,
          specificEquipmentMake: "",
          specificEquipmentModel: "",
          isOriginalProduct: null
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleSubmit(values);
        }}
      >
        {({ isSubmitting,setFieldValue,touched,errors }) => (

         <div>
           <p className="material-part-detail">{localStorage.getItem('MaterailTypeValue')} Details</p>
         <p className="material-part-detail-subhero">Add your Material Details here</p>
          <Form id="post-material-step-one">
            <div>
              <div>
                <div className="name-of-product-number">
                <div className="grid-item">
                  <p ><span className="fieldHeading">Name of Product</span><span className="must">*</span></p>
                  <Field className="filed-input" type="text" name="productName"
                    placeholder="Product Name"/>
                  <ErrorMessage name="productName" component="div" 
                  style={{ 
                    color: "red",
                    fontFamily: "Assistant",
                    fontSize: "12.989px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.22px",
                    marginTop:"5px"
                }} 
                  />
                </div>
                <div lassName="grid-item">
                <p ><span className="fieldHeading">Product Number</span><span className="must">*</span></p>
                
                  <Field
                    className="filed-input"
                    type="text"
                    name="productnumber"
                    placeholder="Product Number"
                  />
                  <ErrorMessage name="productName" component="div"
                  style={{ 
                    color: "red",
                    fontFamily: "Assistant",
                    fontSize: "12.989px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.22px",
                    marginTop:"5px"
                }} 
                  />
                </div>
                </div>
                <div className="name-of-product-number">
                <div style={{ marginTop: "17px",width:"486.778px" }}>
                  <p ><span className="fieldHeading">Price Range</span><span className="must">*</span></p>
                  <div className="price-range-filed">
                    <Field
                      className="filed-input-price-range"
                      type="text"
                      name="pricerangeminimum"
                      placeholder="&#x20b9; Enter Minimum Price"
                    />
                    <Field
                      className="filed-input-price-range"
                      type="text"
                      name="pricerangemaximum"
                      placeholder="&#x20b9; Enter Maximum Price"
                    />
                  </div>
                  <div style={{display:"flex",flexDirection:"row",gap:"133px"}}>
                  <ErrorMessage name="pricerangeminimum" component="div"
                  style={{ 
                    color: "red",
                    fontFamily: "Assistant",
                    fontSize: "12.989px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.22px",
                    marginTop:"5px"
                }}  />
                  <ErrorMessage name="pricerangemaximum" component="div"
                  style={{ 
                    color: "red",
                    fontFamily: "Assistant",
                    fontSize: "12.989px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.22px",
                    marginTop:"5px"
                }}  />
                  </div>
                </div>

                <div className="NameofProductField" style={{ marginTop: "17px" }}>
                  {/* <p ><span className="fieldHeading">Available Location</span><span className="must">*</span></p> */}
                  

                  {/* <Field
                    className="filed-input"
                    type="text"
                    name="avilabelocation"
                    placeholder="Enter Location.."
                  /> */}

                     <Field name="_jobLocation"
                     className="filed-input">
                    {({field}) => (
                      <LocationInput
                        placeholder="Enter Location.."
                        className="filed-input"
                        name='_jobLocation'
                        label="Available Location"
                        isRequired={true}
                        width="484px"
                        height="59.193px"
                        onChange={(value) =>
                          setFieldValue("_jobLocation", value)
                        }
                      />
                    )}
                  </Field>
                  <ErrorMessage name="_jobLocation" component="div"
                  style={{ 
                    color: "red",
                    fontFamily: "Assistant",
                    fontSize: "12.989px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    letterSpacing: "0.22px",
                    marginTop:"5px"
                }}  />
                </div>
                </div>
      
              </div>
              
              <div>
                <div className="NameofProductField">
              
                  <div className="equipment-type-layout" >
                    <div>
                    <p ><span className="fieldHeading">Equipment Type</span><span className="must">*</span></p>
                    {/* <p className="fieldHeading">Equipment Type?*</p> */}
                    <div className="equipment-type-button">
                      
                      <div style={{display:"flex",flexDirection:'row',gap:"22px"}}>
                      <div
                        className={`For-any-Equipment-option ${
                          selectedType === "any" ? "selected" : ""
                        }`}
                        onClick={() => {
                          handleSelectType("any");
                          setFieldValue("equipmentTypeSelection", "any");
                        }}
                      >
                        <p className={`${selectedType==="any"?'For-any-Equipment-text-any':'For-any-Equipment-text'}`} >
                          For Any Equipment
                          {selectedType === "any" && (
                          <ReactSVG
                            src={
                              "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg"
                            }
                          />
                        )}
                        </p>
                        
                      </div>


                      <ReactSVG
                        src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/filedpathSeprator.svg`}
                      />

                      <div
                        className={`For-any-Equipment-option ${
                          selectedType === "specific" ? "selected" : ""
                        }`}
                        onClick={() => {
                          handleSelectType("specific");
                          setFieldValue("equipmentTypeSelection", "specific");

                        }}
                      >
                        <p className={`${selectedType==="specific"?'For-any-Equipment-text-specific':'For-any-Equipment-text'}`}>
                          For Specific Equipment
                          {selectedType === "specific" && (
                          <ReactSVG
                            src={
                              "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg"
                            }
                          />
                        )}
                        </p>
                        
                      </div>
                      </div>
                    
                    </div>
                    </div>
                  
                  
                    <div>
                      {selectedType === "specific" && (
                      <div className="specific-equipment-input">
                         <p ><span className="fieldHeading">Specify Equipment Type</span><span className="must">*</span></p>
                        {/* <p className="fieldHeading">Specify Equipment Type</p> */}
                        <Field
                          className="filed-input"
                          as="select"
                          name="specificEquipment"
                          value={SelectedEquipmentType}
                          onChange={(e) => {
                            setSelectedEquipmentType(e.target.value);
                          }}
                        >
                          <option value="">Select an option</option>
                          {EquipmentList?.map((item, index) => (
                            <option key={index.id} value={item.EquipmentTypeId}>
                              {item?.EquipmentTypeValue}
                            </option>
                          ))}
                        </Field>
                        
                      </div>
                    )}
                      </div>
                  
                  </div>
                  {errors.equipmentTypeSelection && touched.equipmentTypeSelection && (
        <div style={{
          color: "red",
          fontFamily: "Assistant",
          fontSize: "12.989px",
          fontWeight: 600,
          marginTop: "5px"
        }}>
          {errors.equipmentTypeSelection}
        </div>
      )}
                  {SelectedEquipmentType ?
                  (

                    <div className="equipment-type-layout">
                      <div>
                      {/* <p className="fieldHeading">Equipment Make*</p> */}
                      <p ><span className="fieldHeading">Equipment Make</span><span className="must">*</span></p>
                      
                      <div className="equipment-type-button">
                        <div
                          className={`For-any-Equipment-option ${
                            selectedTypeOEM === "anyOEM" ? "selectedOEM" : ""
                          }`}
                          onClick={() => handleSelectTypeOEM("anyOEM")}
                        >
                          <p className={`${selectedTypeOEM==="anyOEM"?'For-any-Equipment-text-make':'For-any-Equipment-anymake'}`}>For Any OEM</p>
                          {selectedTypeOEM === "anyOEM" && (
                            <ReactSVG
                              src={
                                "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg"
                              }
                            />
                          )}
                        </div>

                        <ReactSVG
                          src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/filedpathSeprator.svg`}
                        />
                        <div
                          className={`For-any-Equipment-option ${
                            selectedTypeOEM === "specificOEM" ? "selectedOEM" : ""
                          }`}
                          onClick={() => handleSelectTypeOEM("specificOEM")}
                        >
                          <p  className={`${selectedTypeOEM==="specificOEM"?'For-any-Equipment-text-make':'For-any-Equipment-anymake'}`}>
                            For Specific OEM
                          </p>
                          {selectedTypeOEM === "specificOEM" && (
                            <ReactSVG
                              src={
                                "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg"
                              }
                            />
                          )}
                        </div>
                      </div>
                        </div>
                    
                      {selectedTypeOEM === "specificOEM" && (
                        <div className="specific-equipment-input">
 <p ><span className="fieldHeading">Specify Equipment Make</span><span className="must">*</span></p>
                          {/* <p className="fieldHeading">Specify Equipment Make</p> */}
                          <Field
                            className="filed-input"
                            as="select"
                            name="specificEquipmentMake"
                            value={SelectedEquipmentModel}
                            onChange={(e) => {
                              setSelectedEquipmentModel(e.target.value);
                            }}
                          >
                            <option value="">Select an option</option>
                            {EquipmentMake?.map((item, index) => (
                              <option key={index.id} value={item.OEMId}>
                                {item.OEMName}
                              </option>
                            ))}
                          </Field>
                          <ErrorMessage
                            name="specificEquipment"
                            component="div"
                          />
                        </div>
                      )}
                    </div>
                  ):(<div></div>)
                  }
                </div>
              </div>
              <div>
                <div className="NameofProductField">
                  { SelectedEquipmentType && SelectedEquipmentModel ?
                  (
                    <div className="equipment-type-layout">
                      <div>
                      <p ><span className="fieldHeading">Equipment Model</span><span className="must">*</span></p>
                      {/* <p className="fieldHeading">Equipment Model*</p> */}
                      <div className="equipment-type-button">
                        <div
                          className={`For-any-Equipment-option ${
                            selectedTypeModel === "anyModel"
                              ? "selectedModel"
                              : ""
                          }`}
                          onClick={() => handleSelectTypeModel("anyModel")}
                        >
                          <p className={`${selectedTypeModel==="anyModel"?'For-any-Equipment-text-any':'For-any-Equipment-text'}`} >For Any Model</p>
                          {selectedTypeModel === "anyModel" && (
                            <ReactSVG
                              src={
                                "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg"
                              }
                            />
                          )}
                        </div>

                        <ReactSVG
                          src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/filedpathSeprator.svg`}
                        />
                        <div
                          className={`For-any-Equipment-option ${
                            selectedTypeModel === "specificModel"
                              ? "selectedModel"
                              : ""
                          }`}
                          onClick={() => handleSelectTypeModel("specificModel")}
                        >
                          <p className={`${selectedTypeModel==="specificModel"?'For-any-Equipment-text-specific':'For-any-Equipment-text'}`}>
                            For Specific Model
                          </p>
                          {selectedTypeModel === "specificModel" && (
                            <ReactSVG
                              src={
                                "https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg"
                              }
                            />
                          )}
                        </div>
                      </div>
                      </div>
                      
                      {selectedTypeModel === "specificModel" && (
                        <div className="specific-equipment-input">
                           <p ><span className="fieldHeading">Specify Equipment Model</span><span className="must">*</span></p>
                          {/* <p className="fieldHeading">Specify Equipment Model</p> */}
                          <Field
                            className="filed-input"
                            as="select"
                            name="specificEquipmentModel"
                            value={SelectedEquipmentmake}
                            onChange={(e) => {
                              setSelectedEquipmentmake(e.target.value);
                            }}
                          >
                            <option value="">Select an option</option>
                            {EquipmentModel?.length > 0 &&
                              EquipmentModel?.map((item, index) => (
                                <option key={index.id} value={item?.ModelId}>
                                  {item?.ModelName}
                                </option>
                              ))}
                          </Field>
                          <ErrorMessage
                            name="specificEquipment"
                            component="div"
                            style={{ 
                              color: "red",
                              fontFamily: "Assistant",
                              fontSize: "12.989px",
                              fontStyle: "normal",
                              fontWeight: 600,
                              lineHeight: "normal",
                              letterSpacing: "0.22px",
                              marginTop:"5px"
                          }} 
                          />
                        </div>
                      )}
                    </div>
                  ) :(<div></div>)}
                </div>
                <br/>
                <div className="NameofProductField">
                <p ><span className="fieldHeading">Original Product</span><span className="must">*</span></p>
    <div className="yes-no-button" style={{marginTop:"12px",marginBottom:"12px"}}>
      <div style={{ display: "flex", flexDirection: "row", gap: "22px",marginTop:"6px",marginLeft:"38px"}}
   
    >
        <p style={{ cursor: "pointer", display: "flex", flexDirection: "row", gap: "5px", width: "45px" }} 
         className={`${isOriginalProduct===1?'For-any-Equipment':'For-any-Equipment-original'}`}
        onClick={
          
          ()=>{
            setisOriginalProduct(1);
                    setFieldValue('isOriginalProduct', 1);
          }}>
          Yes
          {isOriginalProduct === 1 && (
            <ReactSVG src="https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg" />
          )}
        </p>
      </div>
      <ReactSVG src={`https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/filedpathSeprator.svg`} />
      <p style={{ cursor: "pointer", display: "flex", flexDirection: "row", gap: "5px", width: "45px",marginTop:"6px",marginRight:"27px" }} 
       className={`${isOriginalProduct===0?'For-any-Equipment':'For-any-Equipment-original'}`}
      onClick={()=>{
        setisOriginalProduct(0);
        setFieldValue('isOriginalProduct', 0);
      }}>
        No
        {isOriginalProduct === 0 && (
          <ReactSVG src="https://dev-static.equip9.com/Webapp/Advertisements/LargeAdvertisments/icons/SelectedCard.svg" />
        )}
      </p>
    </div>
  </div>
  <Field type="hidden" name="isOriginalProduct" />

        {errors.isOriginalProduct && touched.isOriginalProduct && (
          <div style={{ 
            color: "red",
            fontFamily: "Assistant",
            fontSize: "12.989px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            letterSpacing: "0.22px",
            marginTop:"5px"
        }} 
        
          >{errors.isOriginalProduct}</div>
        )}
  </div>
  </div>
            {/* <div className='bottom-bar-header-step1'>
                      <div className='button-layout-step1'>
                    
                        <button type="submit" className="accept-button-ongoing" disabled={isSubmitting} >
                        <p className="canceljob-text">Submit</p>
                        </button>
                      </div>
              </div>  */}

        
          </Form>
          </div>
        )}
      </Formik>
    );
  };

  // SelectedCard.svg
