import "./MaterialStorelist.css";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate} from "react-router-dom";
import {
  AlertDialog,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
} from "@chakra-ui/react";
import { ReactSVG } from "react-svg";
import { useQuery, useQueryClient} from "react-query";
import { GetMaterialType, createMaterial } from "../../../ApiEquip9/CommonApi";
import { convertToBase64 } from "../../../utils/formatConverter/convertToBase64";

export const MaterialStorePopup = ({
  isLogoutModalOpenPopUp,
  setIsLogoutModalOpenPopUp,
}) => {
  const userData = useSelector((state) => state.user);
  const queryClient = useQueryClient();
  const onCloseLogoutModal = () => setIsLogoutModalOpenPopUp(false);
  const [MaterialTypeid, setMaterialTypeid] = useState(null);
  const [MaterialTypevalue,setMaterialTypevalue]=useState(null)
  const cancelReff = React.useRef();
  const toast = useToast();
  const navigate=useNavigate();
  const convertToBase64MaterialId=convertToBase64(MaterialTypeid);
  // console.log(convertToBase64MaterialId,'convertToBase64MaterialId')

  /********************useQuery call************/
  const { data: getMaterialList } = useQuery("getmateriallist", () =>
    GetMaterialType(userData.token)
  );

  /***********Get Material list*********************/
  const resultList = getMaterialList?.data?.Data?.MaterialType;

  /********************on select store option*********/
  const onselectStore = (id,MaterialTypeValue) => {
    setMaterialTypeid(id);
    setMaterialTypevalue(MaterialTypeValue)
  };

  /**************submit store ******************/

  const handleSelectedOption = async () => {
    const payload = {
      MaterialTypeId: MaterialTypeid,
    };
    try {
      const response = await createMaterial(userData.token, payload);
      if (response.data?.Status === 201) {
        toast({
          title: "",
          description: `${response?.data?.Message.SuccessMessage}`,
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top-right",
        });
        queryClient.invalidateQueries("getmaterialStore");
        queryClient.invalidateQueries('getmateriallistt')
        setIsLogoutModalOpenPopUp(false);
      }
      if (response?.data?.Status === 409) {
        localStorage.setItem("EditMaterialId",convertToBase64MaterialId)
        navigate(`/material/${convertToBase64MaterialId}`,{
            state: { MaterailTypeValue: MaterialTypevalue }
        })
        queryClient.invalidateQueries("getmaterialStore");
        setIsLogoutModalOpenPopUp(false);
      }
    } catch (error) {
      toast({
        title: "",
        description: `Error `,
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <AlertDialog
        isOpen={isLogoutModalOpenPopUp}
        leastDestructiveRef={cancelReff}
        onClose={onCloseLogoutModal}
        isCentered
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>
              <div
                className="material-pop-up-close"
                onClick={onCloseLogoutModal}
              >
                <ReactSVG
                   style={{cursor:'pointer'}}
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/Materialpopclose.svg`}
                />
              </div>
              <div className="Material-pop-header-layout">
                <ReactSVG
                  src={`${process.env.REACT_APP_CDN_STATIC_URL}Webapp/Advertisements/LargeAdvertisments/icons/MatrailStore.svg`}
                />
                <p className="Material-pop-header-heading">Materials Store</p>
                <p className="Material-pop-header-subheading">
                  Choose your Material Type
                </p>
              </div>
            </AlertDialogHeader>
            <div>
              <div className="options-layout">
                <div className="scrollable-container">
                  {" "}
                  {resultList?.map((item, index) => (
                    <div
                    key={item.MaterialTypeId} 
                      className={`store-options-list ${MaterialTypeid === item.MaterialTypeId ? 'selected' : ''}`}
                      onClick={() => onselectStore(item?.MaterialTypeId,item?.MaterialTypeValue)}
                    >
                      <img
                        className={`list-icon-image ${MaterialTypeid === item.MaterialTypeId ? 'selected' : ''}`}
                        src={`${process.env.REACT_APP_CDN_PVT_URL}MobileAppAssets/Material/${item.MaterialTypeIconFileRef}`}
                      />
                      <p className="spare-parts-heading">
                        {item.MaterialTypeValue}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="pop-up-footer">
              <button
                className="Next-button"
                type="button"
                onClick={handleSelectedOption}
              >
                <p className="next-button-text">Next</p>
              </button>
            </div>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
};
