import { useEffect, useRef, useState } from "react";
import { V5FixedFooterSidebarLayout } from "../../../layout/V5FixedFooterSidebarLayout/V5FixedFooterSidebarLayout";
import { getTransactionState } from "../../../services/subscription/subscription";
import { setTranactionState } from "../../subscription/slice/subscriptionSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPremiumTypes,
  renewPremiumService,
  savePremiumUser,
  saveUserPremiumType,
} from "../../../services/premium/premium";
import { premiumTypeThunk } from "../thunks/getPremiumTypeThunk";
import { Box, Flex, Heading, Text } from "@chakra-ui/react";
import { PremiumTypeBadge } from "../../../components/PremiumTypeBadge/PremiumTypeBadge";
import {
  setConfirmedPremiumTypeId,
  setPremiumOrderId,
  setPremiumTransactionDetailId,
  setSelectedPremiumBenefitsList,
  setSelectedPremiumCost,
  setSelectedPremiumDiscount,
  setSelectedPremiumDiscountPercentage,
  setSelectedPremiumMonthlyCost,
  setSelectedPremiumPrice,
  setSelectedPremiumTypeId,
} from "../slice/premiumSlice";
import { getPremiumDetailsThunk } from "../thunks/getPremiumDetailsThunk";
import { PremiumBenefitsTable } from "../../../components/PremiumBenefitsTable/PremiumBenefitsTable";
import { v4 as uuid } from "uuid";
import { useNavigate } from "react-router-dom";
import { convertToBase64 } from "../../../../utils/formatConverter/convertToBase64";
import { BrandLoader } from "../../../components/loaders/brandLoader/BrandLoader";
import { setIsPremium, setPremiumId } from "../../premium/premiumSlice";

export const PremiumBenefits = () => {
  const { transactionState } = useSelector((state) => state.subscription);
  const {
    premiumTypes,
    isPremiumTypesLoading,
    isPremiumBenefitsLoading,
    selectedPremiumTypeId,
    selectedPremiumBenefitsList,
    selectedPremiumPrice,
    selectedPremiumDiscountPrecent,
    selectedPremiumMonthlyCost,
    selectedPremiumDiscount,
    selectedPremiumCost,
    confirmedPremiumTypeId,
  } = useSelector((state) => state.buyPremium);
  const {isPremium, premiumDaysRemainingToExpire} = useSelector((state) => (state.premium))
  const { currencyDetails } = useSelector((state) => state.user);
  const [isCheckoutLoading, setIsCheckoutLoading] = useState(false);
  const [isGoBackLoading, setIsGoBackLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const handleAlreadyPremiumBuyer = () => {
  //   if(isPremium && premiumId)
  // }

  const handleCheckoutButton = async () => {
    // update transaction state
    try {
      setIsCheckoutLoading(true);
      const data = await saveUserPremiumType(
        selectedPremiumTypeId,
        transactionState[1].TransactionStateId
      );
      const body = {
        PaymentType: convertToBase64(selectedPremiumTypeId),
        TotalCost: selectedPremiumCost,
        Discount: selectedPremiumDiscount,
        FinalCost: selectedPremiumPrice,
      };
      let checkoutData = ""
      if(isPremium && premiumDaysRemainingToExpire <= 7){
         checkoutData = await renewPremiumService({...body, PlanExpiryDate : premiumDaysRemainingToExpire})
      }
       else{
        checkoutData = await savePremiumUser(body);
      }
      dispatch(setConfirmedPremiumTypeId(selectedPremiumTypeId));
      dispatch(
        setPremiumTransactionDetailId(
          checkoutData.E9PremiumTransactionDetailsId
        )
      );
      dispatch(setPremiumOrderId(checkoutData.OrderId));
      dispatch(setConfirmedPremiumTypeId(selectedPremiumTypeId));
      navigate("/subscription/premium-checkout");
    } catch (error) {
      console.log(error);
    } finally {
      setIsCheckoutLoading(false);
    }

    // console.log(selectedPremiumTypeId)
  };

  const handleGoBackClickAction = async () => {
    try {
      setIsGoBackLoading(true);
      const data = await saveUserPremiumType(
        convertToBase64(selectedPremiumTypeId),
        transactionState[0].TransactionStateId
      );
    } catch (error) {
      console.log(error);
    } finally {
      setIsGoBackLoading(false);
      navigate("/dashboard");
    }
  };

 

  useEffect(() => {
    (async () => {
      try {
        const data = await getTransactionState();

        if (data.Status) {
          dispatch(setTranactionState(data.Data.data));
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    dispatch(premiumTypeThunk());
    // handlePremiumUserDetails();
    
  }, []);

  useEffect(() => {
    if (!isNaN(selectedPremiumTypeId)) {
      dispatch(getPremiumDetailsThunk(selectedPremiumTypeId));
    }
  }, [selectedPremiumTypeId]);
  return isPremiumBenefitsLoading ? (
    <BrandLoader />
  ) : (
    <V5FixedFooterSidebarLayout
      primaryButtonVariant="gradient"
      primaryButtonText={
        transactionState && transactionState.length > 0
          ? transactionState[1].TransactionStateValue
          : ""
      }
      secondaryText={
        transactionState && transactionState.length > 0
          ? transactionState[0].TransactionStateValue
          : ""
      }
      secondaryElement="button"
      primaryButtonClickAction={handleCheckoutButton}
      secondaryButtonClickAction={handleGoBackClickAction}
      primaryButtonLoading={isCheckoutLoading}
      secondaryButtonLoading={isGoBackLoading}
    >
      <Box padding="0 52px">
        <Box>
          <Heading>Premium Benefits</Heading>
        </Box>
        <Flex
          gap="1rem"
          width="100%"
          overflowX="scroll"
          paddingTop="1rem"
          paddingBottom="1rem"
        >
          {premiumTypes && premiumTypes.length ? (
            premiumTypes.map((premiumType) => (
              <PremiumTypeBadge
                key={uuid()}
                onClick={() => {
                  dispatch(setSelectedPremiumTypeId(premiumType.PaymentPlanId));
                }}
                paymentPlan={premiumType.PaymentPlanValue}
                price={ `${currencyDetails ? currencyDetails?.CurrencyValue : ""} ${premiumType.PaymentPlanCost}`}
                discountPrecentage={10}
                paymentCycle={premiumType.PaymentPlanCycle}
                isSelected={selectedPremiumTypeId === premiumType.PaymentPlanId}
              />
            ))
          ) : (
            <></>
          )}
        </Flex>
        <Flex width="100%" gap="1rem">
          <Box flex="1">
           
            <PremiumBenefitsTable>
              <PremiumBenefitsTable.Header headings={["E9Services", "Count"]} />
              <PremiumBenefitsTable.Body
                content={
                  selectedPremiumBenefitsList ? selectedPremiumBenefitsList : []
                }
              />
            </PremiumBenefitsTable>
          </Box>
          <Box
            flex="2"
            minWidth="300px"
            maxWidth="300px"
            minHeight="310px"
            maxHeight="310px"
            border="2px dashed #319F00"
            borderRadius="4px"
            marginTop="40px"
          >
            <Flex
              height="100%"
              minHeight="inherit"
              width="100%"
              flexDirection="column"
              gap="1rem"
              paddingBottom="16px"
              justifyContent="space-between"
            >
              <Box
                color="#FFF"
                bgColor="#319F00"
                padding="4px 10px"
                textAlign="center"
              >
                <Text fontWeight="700">Total Amount To Pay</Text>
              </Box>
              <Box padding="0 10px" textAlign="center">
                <Text fontSize="14px" color="#767676">
                  This is the total amount for your purchase. Review your plan
                  and proceed to payment.
                </Text>
              </Box>
              <Flex padding="0 10px" justifyContent="space-between">
                <Text color="#002961" fontWeight="600">
                  Overall Cost:{" "}
                </Text>
                <Text fontWeight="700">
                  {currencyDetails?.CurrencyValue}{" "}
                  {selectedPremiumCost ? selectedPremiumCost : 0}
                </Text>
              </Flex>
              <Flex padding="0 10px" justifyContent="space-between">
                <Text color="#002961" fontWeight="600">
                  Premium Benefit:{" "}
                </Text>
                <Text color="#267A00" fontWeight="700">
                  {selectedPremiumCost ? selectedPremiumDiscountPrecent : 0} %
                </Text>
              </Flex>
              <hr style={{ backgroundColor: "#E3E5E8" }} />
              <Flex
                padding="0 10px"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text color="#141619" fontWeight="700" fontSize="20px">
                  Total Price:{" "}
                </Text>
                <Text color="#002961" fontWeight="700" fontSize="28px">
                  {currencyDetails?.CurrencyValue}{" "}
                  {selectedPremiumPrice ? selectedPremiumPrice : 0}
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Flex>
      </Box>
    </V5FixedFooterSidebarLayout>
  );
};
