import { createAsyncThunk } from "@reduxjs/toolkit";
import { createCompanySubscriptionTransactionThunk } from "./createCompanySubscriptionTransactionThunk";
import { createCompSubscriptionDetails } from "../../../services/subscription/subscription";

export const createCompanySubscriptionThunk = createAsyncThunk(
  "/subscription/createCompanySubscription",
  async (body, { getState, dispatch }) => {
    const { accountDetails } = getState().user;
    const { selectedSubscriptionType, transactionState } =
      getState().subscription;

    const apiBody = body.body.map((service) => {
      const {
        ServiceTypeId,
        ServiceContactUnitPrice,
        ServiceDiscountTypeValue,
        quantitySelected,
      } = service;
      const serviceBody = {
        CompanyTaxNumber: accountDetails.CompanyDetails.TaxNumber,
        SubscriptionTypeId: selectedSubscriptionType.SUBSCRIPTION_ID,
        TransactionStateId: parseInt(body.transactionStateId),
        ServiceTypeId,
        SelectedContactsCount: quantitySelected,
        SelectedContactPrice: parseFloat(
          ServiceContactUnitPrice * quantitySelected
        ).toFixed(2),
        TotalDiscountAppliedPrice: parseFloat(
          (ServiceContactUnitPrice *
            quantitySelected *
            ServiceDiscountTypeValue) /
            100
        ).toFixed(2),
        FinalCostAfterDiscount:
          parseFloat(ServiceContactUnitPrice * quantitySelected).toFixed(2) -
          parseFloat(
            (ServiceContactUnitPrice *
              quantitySelected *
              ServiceDiscountTypeValue) /
              100
          ),
      };
      return serviceBody;
    });
    // console.log(apiBody);
    try {

      const data = await createCompSubscriptionDetails(apiBody);

      if (data.Status === 201) {
        if (
          parseInt(body.transactionStateId) ===
          transactionState[1].TransactionStateId
        ) {
          sessionStorage.setItem("ListofSelectedService",data?.Data?.ListofSelectedService)
          await dispatch(
            createCompanySubscriptionTransactionThunk({
              ...body,
              ListofSelectedService: data.Data.ListofSelectedService,
            })
            
          ).unwrap();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
);
