import React, { useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import { ChakraProvider } from "@chakra-ui/react";
import "./App.css";
import Terms from "./website/Pages/Terms";
import { SelectE9Role } from "./webapp/features/documentFlow/screens/selectE9Role/SelectE9Role";
import { chTheme } from "./webapp/theme/chakraTheme";
import AboutUs from "./webapp/features/dashboard/screens/dashboard/LegalPolicy/AboutUs";
import TermsAndCondition from "./webapp/features/dashboard/screens/dashboard/LegalPolicy/TermsAndCondition";
import ContactUs from "./webapp/features/dashboard/screens/dashboard/LegalPolicy/ContactUs";
import LegalPrivacyPolicy from "./webapp/features/dashboard/screens/dashboard/LegalPolicy/LegalPrivacyPolicy";
import { Settings } from "./webapp/features/Settings/Settings";
import { PrivacySettings } from "./webapp/features/Settings/PrivacySettings";
import { ChangePassword } from "./webapp/features/Settings/ChangePassword";
import { MpOngoing } from "./webapp/features/Upcoming/MPOngoingJobStatus";

import "@fontsource/assistant/200.css";
import "@fontsource/assistant/300.css";
import "@fontsource/assistant/400.css";
import "@fontsource/assistant/500.css";
import "@fontsource/assistant/600.css";
import "@fontsource/assistant/700.css";
import "@fontsource/assistant/800.css";
import "@fontsource/berkshire-swash";

import { Dashboard } from "./webapp/features/dashboard/screens/dashboard/Dashboard";
import { MyProfile } from "./webapp/features/dashboard/screens/dashboard/myprofile/MyProfile";
import { ProfileDetailsForm } from "./webapp/features/dashboard/screens/dashboard/myprofile/myProfileSection/myProfileDetails/ProfileDetails/ProfileDetailsForm";
import { VerficationDetail } from "./webapp/features/dashboard/screens/dashboard/myprofile/VerficationDetails/VerficationDetails";
import { BusinessDetail } from "./webapp/features/dashboard/screens/dashboard/myprofile/BusinessDetail/BusinessDetail";
import { OperatorNeaMeMarketplace } from "./webapp/features/operatorNearMe/screens/operatorNearMeMarketplace/OperatorNearMeMarketplace";
import { MaintenancePartnerNeaMeMarketplace } from "./webapp/features/maintenancePartnerNearMe/screens/MaintenancePartnerNearMe";
import { OperatorJobsNearMe } from "./webapp/features/operatorJobs/screens/operatorJobsNearMe/OperatorJobsNearMe";
import { MaintenanceJobsNearMe } from "./webapp/features/maintenanceJobs/screens/maintenanceJobsNearMeMarketplace/MaintenanceJobsNearMeMarketplace";
import { EquipmentDetails } from "./webapp/features/dashboard/screens/dashboard/myprofile/Opearatorpatner/Equipmentdeatail/EquipmentDetails";
import { OpEducationDetails } from "./webapp/features/dashboard/screens/dashboard/myprofile/Opearatorpatner/Equipmentdeatail/OpEducationdetail/OpEducationDetail";
import { LicenseDetails } from "./webapp/features/dashboard/screens/dashboard/myprofile/Opearatorpatner/Equipmentdeatail/LicenseDetail/LicenseDetail";
import { WorkExpirence } from "./webapp/features/dashboard/screens/dashboard/myprofile/Opearatorpatner/Equipmentdeatail/WorkExpirence/WorkExpirence";
import { AssetDetailsPage } from "./webapp/components/AssetDetailsPage/AssetDetailsPage";
import { PartnerDetailsPage } from "./webapp/components/PartnerDetailsPage/PartnerDetailsPage";
import { BoCompanyProfile } from "./webapp/features/dashboard/screens/dashboard/myprofile/Company/CompnayProfile/CompanyProfile";
import { Subcription } from "./webapp/features/dashboard/screens/dashboard/myprofile/Company/CompnayProfile/SubcriptionDetails/Subcription";
import { JobDetailsPage } from "./webapp/components/JobDetailsPage/JobDetailsPage";

import Warning from "./website/CommonComponents/ErrorPages/Warning";

import { UsedEquipmentMarketplace } from "./webapp/features/usedEquipmentMarketplace/screens/UsedEquipmentMarketplace";
import { RentalEquipmentMarketplace } from "./webapp/features/rentalEquipmentMarketplace/screens/RentalEquipmentMarketplace";
import { Equip9Support } from "./webapp/features/dashboard/screens/dashboard/Equip9Support/Equip9Suppport";
import { SubscriptionType } from "./webapp/features/subscription/screens/SubscriptionType/SubscriptionType";
import { SubscriptionServices } from "./webapp/features/subscription/screens/SubscriptionServices/SubscriptionServices";
import { UpdateAddress } from "./webapp/features/subscription/screens/UpdateAddress/UpdateAddress";
import { SubscriptionReview } from "./webapp/features/subscription/screens/SubscriptionReview/SubscriptionReview";
import { Login } from "./webapp/features/login/screens/login/Login";
import { PaymentSuccess } from "./webapp/features/subscription/screens/PaymentSuccess/PaymentSuccess";
import { PurchasedSubscriptions } from "./webapp/features/subscription/screens/PurchasedSubscriptions/PurchasedSubscriptions";
import { MyFreeContact } from "./webapp/features/subscription/screens/MyFreeContact/MyFreeContact";
import { TransactionHistory } from "./webapp/features/transactions/screens/TransactionHistory/TransactionHistory";
import OurProduct from "./website/Pages/Product/OurProduct";
import { JobStatus } from "./webapp/features/Jobstatus/JobStatus";
// import BrowserCheck from "./CrossBrowser";
import { DeleteAccount } from "./webapp/features/Settings/DeleteAccount";
import { CompanyMemberDetails } from "./webapp/features/dashboard/screens/dashboard/myprofile/Company/CompnayProfile/Comapany Member Details/ComapnyMemberDetails";
import { E9Services } from "./webapp/features/e9Services/screens/E9Services";
import { OperatorJobPost } from "./webapp/features/operatorSearch/screen/OperatorJobPost/OperatorJobPost";

import DealershipMarketplace from "./webapp/features/dealershipMarketplace/screens/DealershipMarketplace";

import { MaterialAssestPage } from "./webapp/features/MaterialMarketplace/screens/MaterialAssestPage";
import { SparePartMaterialMarketplace } from "./webapp/features/sparePartsMaterialMarketplace/screens/SparePartMaterialMarketplace";
import { OilMaterialMarketplace } from "./webapp/features/oilMaterialMarketplace/screens/OilMaterialMarketplace";
import { ConcreteMaterialMarketplace } from "./webapp/features/concreteMaterialMarketplace/screens/ConcreteMaterialMarketplace";
import { GeneratorsMaterialMarketplace } from "./webapp/features/generatorsMaterialMarketplace/screens/GeneratorsMaterialMarketplace";
import { SafetyEquipmentMaterialMarketplace } from "./webapp/features/safetyEquipmentMaterialMarketplace/screens/SafetyEquipmentMaterialMarketplace";
import { MaterialDetail } from "./webapp/components/MaterialDetailsPage/MaterialDetail";
import { DealershipDetail } from "./webapp/components/DealershipDetail/DealershipDetail";
import { MaintenanceJobPost } from "./webapp/features/maintenanceJobPost/sceens/MaintenaneJobPost/MaintenanceJobPost";

import { UpcomingJobStatus } from "./webapp/features/Upcoming/UpcomingJobStaus";
import { Mpupcoming } from "./webapp/features/Upcoming/MpUpcomingJobStatus";
import { MyBusinessAsset } from "./webapp/components/MyBusiness/MyBusinessAsset";
import MyBusinessDealershipListing from "./webapp/features/MyBusinessDealershipDetail/screens/MyBusinessDealershipListing";
import { MpCompleted } from "./webapp/features/Upcoming/MPCompletedJobStatus";
import { EquipmentListingForm } from "./webapp/features/equipmentListing/screens/EquipmentListingForm/EquipmentListingForm";
import { PostDealershipSteps } from "./webapp/features/MyBusinessPostDealership/AddDealershipSteps/PostDealershipSteps";
import { Materialstorelist } from "./webapp/features/MaterialStorelist/MaterialStorelist";
import { MyBusinessStore } from "./webapp/components/MaterailStoreList/MyBusinessStore/MyBusinessStore";
import { MaterialStorePopup } from "./webapp/components/MaterailStoreList/MaterialStorelist";
import { PostMaterialSteps } from "./webapp/components/MaterailStoreList/MyBusinessStore/AddMarketPlace/MarketPlaceSteps/PostMaterialSteps";
import { MyJobs } from "./webapp/features/postedJobs/screens/MyJobs/MyJobs";
import { About } from "./website/Pages/About/About";
import { Contact } from "./website/Pages/Contact/Contact";
import { Home } from "./website/Pages/Home/Home";
import OurBlog from "./website/Pages/Blog/OurBlog";
import PrivacyPolicy from "./website/Pages/PrivacyPolicy";
import CookiesNotice from "./website/Pages/CookiesNotice";
import RatingSystem from "./website/Pages/RatingSystem";
import RefundPolicy from "./website/Pages/RefundPolicy";
import { SelectBusinessType } from "./webapp/features/registration/screens/selectBusinessType/SelectBusinessType";
import { SelectUserType } from "./webapp/features/registration/screens/selectUserType/SelectUserType";
import { GenerateOtp } from "./webapp/features/registration/screens/generateOtp/GenerateOtp";
import { VerifyOtp } from "./webapp/features/registration/screens/verifyOtp/VerifyOtp";
import { RegistrationForm } from "./webapp/features/registration/screens/registrationForm/RegistrationForm";
import { GenerateForgetPasswordOtp } from "./webapp/features/forgetPassword/screens/generateForgetPasswordOtp/GenerateForgetPassword";
import { VerifyForgotPasswordOtp } from "./webapp/features/forgetPassword/screens/verifyForgotPasswordOtp/VerifyForgotPasswordOtp";
import { EnterNewPassword } from "./webapp/features/forgetPassword/screens/enterNewPassword/EnterNewPassword";
import { SelectDesignation } from "./webapp/features/documentFlow/screens/selectDesignation/SelectDesignation";
import { UploadProfilePicture } from "./webapp/features/documentFlow/screens/uploadProfilePicture/UploadProfilePicture";
import { UploadEquipmentSkill } from "./webapp/features/documentFlow/screens/uploadEquipmentSkill/UploadEquipmentSkill";
import { UploadVerificationDocument } from "./webapp/features/documentFlow/screens/uploadVerificationDocument/UploadVerificationDocument";
import { UploadBusinessIdentification } from "./webapp/features/documentFlow/screens/uploadBusinessIdentification/UploadBusinessIdentification";
import { UploadLicense } from "./webapp/features/documentFlow/screens/uploadLicense/UploadLicense";
import { AuthRoute } from "./webapp/routes/AuthRoute";
import { PublicRoute } from "./webapp/routes/PublicRoute";
import { PostEditMaterialPlaceStep } from "./webapp/components/MaterailStoreList/MyBusinessStore/AddMarketPlace/MarketPlaceSteps/EditMarketPlace/PostEditMaterialPlaceSteps2";
import { EquipmentStore } from "./webapp/features/equipmentStore/screens/EquipmentStore";
import { UserEquipmentPage } from "./webapp/features/equipmentStore/screens/UserEquipmentPage";
import { AdditionalEquipmentDetails } from "./webapp/features/equipmentStore/screens/AdditionalEquipmentDetails/AdditionalEquipmentDetails";
import { CreateE9MarketplaceDetails } from "./webapp/features/equipmentStore/screens/CreateE9MarketplaceDetails/CreateE9MarketplaceDetails";
// import { MyLeads } from "./webapp/features/myLeads/screens/MyLeads.jsx"
import { MyLeads } from "./webapp/features/myLeads/Screens/MyLeads";
import TabContainer from "./webapp/components/TabContainer/TabContainer";
import AcceptReject from "./webapp/components/TabContainer/AcceptReject";
import DealershipConnectCard from "./webapp/components/TabContainer/DealershipConnectCard";
import { MaterialTypeStoreList } from "./webapp/features/materials/screens/MaterialTypeStore/MaterialTypeStoreList";
import { MyMaterialTypeStore } from "./webapp/features/materials/screens/MyMaterialTypeStore/MyMaterialTypeStore";
import { MaterialDetailPage } from "./webapp/features/materials/screens/MaterialDetailPage/MaterialDetailPage";
import { PostMaterialsSteps } from "./webapp/features/addMaterial/PostMaterialsSteps";
import { ChangeLanguage } from "./webapp/features/Settings/ChangeLanguage";
import { V5SelectLanguage } from "./webapp/features/welcome/screens/selectLanguage/V5SelectLanguage";
import { V5Welcome } from "./webapp/features/welcome/screens/welcome/V5Welcome";
import { V5GetHelpPage } from "./webapp/features/getHelp/screens/GetHelpPage/V5GetHelpPage";
import { useFirebase } from "./webapp/hooks/useFirebase";
import { ShareLoader } from "./website/Pages/ShareLoader/ShareLoader";
import OpenEquipmentMarketplace from "./website/Pages/openMarketplace/equipment/rental/OpenEquipmentMarketplace";
import OpenPartnersDetails from "./website/Pages/openMarketplace/equipment/rental/OpenPartnersDetails";
import { RentalDeferredMarketplace } from "./website/Pages/openMarketplace/screens/RentalDeferredMarketplace";
import { UsedDeferredMarketplace } from "./website/Pages/openMarketplace/screens/UsedDeferredMarketplace";
import { OperatorDeferredMarketplace } from "./website/Pages/openMarketplace/screens/OperatorDeferredMarketplace";
import { MechanicDeferredMarketplace } from "./website/Pages/openMarketplace/screens/MechanicDeferredMarketplace";
import { DealershipDeferredMarketplace } from "./website/Pages/openMarketplace/screens/dealership/DealershipDeferredMarketplace";
import { OperatorJobDeferredMarketplace } from "./website/Pages/openMarketplace/screens/OperatorJobDeferredMarketplace";
import { MaintenanceJobDeferredMarketplace } from "./website/Pages/openMarketplace/screens/MaintenanceJobDeferredMarketplace";
import { OpenJobDetailPage } from "./website/Pages/openMarketplace/job/openJobDetailPage/OpenJobDetailPage";
import MaterialTypesMarketplace from "./website/Pages/openMarketplace/materials/MaterialTypesMarketplace";
import ConcreteDeferred from "./website/Pages/openMarketplace/materials/concreteMaterial/screens/ConcreteDeferred";
import SparePartsDeferred from "./website/Pages/openMarketplace/materials/sparePartsMaterial/screens/SparePartsDeferred";
import OilDeferred from "./website/Pages/openMarketplace/materials/oilMaterial/screens/OilDeferred";
import GeneratorsDeferred from "./website/Pages/openMarketplace/materials/generatorsMaterial/screens/GeneratorsDeferred";
import SafetyDeferred from "./website/Pages/openMarketplace/materials/safetyMaterial/screens/SafetyDeferred";
import DealershipDeferredDetails from "./website/Pages/openMarketplace/screens/dealership/DealershipDeferredDetails";
import MaterialDetailsDeferred from "./website/Pages/openMarketplace/materials/MaterialDetailsDeferred";
import NotAuthorized from "./website/CommonComponents/ErrorPages/NotAuthorized";
import { PremiumBenefits } from "./webapp/features/buyPremium/screens/PremiumBenefits";
import IndustryRequirementListing from "./webapp/features/industryRequirement/screens/IndustryRequirementListing";
import { CommunityPage } from "./webapp/features/community/screens/CommunityPage";
import { MyContentPage } from "./webapp/features/community/screens/MyContentPage";
import { CommunityForm } from "./webapp/features/addCommunityPost/screens/CommunityForm";
import { AllMarketplaces } from "./webapp/features/allMarketplaces/screens/AllMarketplaces";
import { OwnerMarketplace } from "./webapp/features/ownerMarketplace/screens/OwnerMarketplace";
import { OwnerMarketplaceDetails } from "./webapp/features/ownerMarketplace/screens/OwnerMarketplaceDetails";
import { PremiumCheckout } from "./webapp/features/buyPremium/screens/PremiumCheckout";
import MyRequirement from "./webapp/features/industryRequirement/screens/MyRequirement";
import RequirementCategory from "./webapp/features/industryRequirement/screens/RequirementCategory";
import PostYourRequirement from "./webapp/features/industryRequirement/screens/PostYourRequirement";
import IndustryLeads from "./webapp/features/industryRequirement/screens/myLeads/IndustryLeads";
import IndustryRequestDetails from "./webapp/features/industryRequirement/screens/myLeads/IndustryRequestDetails";
import IndustryLikePage from "./webapp/features/industryRequirement/screens/myLeads/IndustryLikePage";
import MyPremium from "./webapp/features/premium/screens/MyPremium";
import OwnersLeads from "./webapp/features/ownerMarketplace/screens/OwnersLeads";
import { PremiumPaymentStatus } from "./webapp/features/buyPremium/screens/PremiumPaymentStatus";
import { QuizListing } from "./webapp/features/quiz/screens/QuizListing";
import { QuizPage } from "./webapp/features/quiz/screens/quizPage/QuizPage";
import { JobCycle } from "./webapp/features/jobCycleOwner/pages/JobCycle";
import { QuizTermsAndConditions } from "./webapp/features/quiz/screens/QuizTermsAndConditions";
import { QuizWinnersPage } from "./webapp/features/quiz/screens/quizWinnersPage/QuizWinnersPage";
import { MyQuizzesPage } from "./webapp/features/quiz/screens/myQuizzesPage/MyQuizzesPage";
import { MyJobPostedDetailPage } from "./webapp/features/Upcoming/MyJobPostedDetailPage";

function App() {
  const { analytics } = useFirebase();

  Sentry.init({
    environment: process.env.REACT_APP_ENV,
    dsn: process.env.REACT_APP_SENTRY_KEY,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 1.0,
  });

  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

  useEffect(() => {
    if (analytics) {
    }
  }, [analytics]);
  return (
    <ChakraProvider theme={chTheme}>
      {/* <ErrorRoutes /> */}
      <SentryRoutes>
        {/* WEBSITE */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/our-product" element={<OurProduct />} />
        <Route path="/our-blog" element={<OurBlog />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookies-notice" element={<CookiesNotice />} />
        <Route path="/rating-system" element={<RatingSystem />} />
        <Route path="/terms-and-conditions" element={<Terms />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/share" element={<ShareLoader />} />

        {/* Deferred Marketplace */}
        <Route
          path="/deferred/marketplace/rental-marketplace"
          element={<RentalDeferredMarketplace />}
        />
        <Route
          path="/deferred/marketplace/used-marketplace"
          element={<UsedDeferredMarketplace />}
        />
        <Route
          path="/deferred/marketplace/partners/operator"
          element={<OperatorDeferredMarketplace />}
        />
        <Route
          path="/deferred/marketplace/partners/mechanic"
          element={<MechanicDeferredMarketplace />}
        />
        <Route
          path="/deferred/marketplace/dealership"
          element={<DealershipDeferredMarketplace />}
        />
        <Route
          path="/deferred/marketplace/jobs/operator"
          element={<OperatorJobDeferredMarketplace />}
        />
        <Route
          path="/deferred/marketplace/jobs/maintenance"
          element={<MaintenanceJobDeferredMarketplace />}
        />

        <Route
          path="/deferred/marketplace/DealershipMarketplace/dealership-detail/:dealershipGuid/:dealershipMpGuid"
          element={<DealershipDeferredDetails />}
        />

        {/* Deferred Materials Marketplace */}
        <Route
          path="/deferred/marketplace/materials"
          element={<MaterialTypesMarketplace />}
        />
        <Route
          path="/deferred/marketplace/materials/spare-parts/:materialTypeValue/:materialTypeId"
          element={<SparePartsDeferred />}
        />
        <Route
          path="/deferred/marketplace/materials/oil/:materialTypeValue/:materialTypeId"
          element={<OilDeferred />}
        />
        <Route
          path="/deferred/marketplace/materials/concrete/:materialTypeValue/:materialTypeId"
          element={<ConcreteDeferred />}
        />
        <Route
          path="/deferred/marketplace/materials/generators/:materialTypeValue/:materialTypeId"
          element={<GeneratorsDeferred />}
        />
        <Route
          path="/deferred/marketplace/materials/safety-equipment/:materialTypeValue/:materialTypeId"
          element={<SafetyDeferred />}
        />
        <Route
          path="/deferred/marketplace/materials-marketplace/material-detail/:materialTypeValue/:materialId/:ownerUserCredentialId"
          element={<MaterialDetailsDeferred />}
        />

        {/* Error Routes */}
        <Route path="/not-authorized" element={<NotAuthorized />} />

        {/* WELCOME */}
        <Route
          path="/welcome/select-language"
          element={
            <PublicRoute>
              <V5SelectLanguage />
            </PublicRoute>
          }
        ></Route>
        <Route
          path="/welcome/welcome"
          element={
            <PublicRoute>
              <V5Welcome />
            </PublicRoute>
          }
        ></Route>
        {/* WELCOME */}

        {/* ṚEGISTRATION */}
        <Route
          path="/register/select-business-type"
          element={<SelectBusinessType />}
        />
        <Route
          path="/register/select-user-type"
          element={
            <PublicRoute>
              <SelectUserType />
            </PublicRoute>
          }
        />
        <Route
          path="/register/generate-otp"
          element={
            <PublicRoute>
              <GenerateOtp />
            </PublicRoute>
          }
        />

        <Route
          path="/register/verify-otp"
          element={
            <PublicRoute>
              <VerifyOtp />
            </PublicRoute>
          }
        />
        <Route
          path="/register/register"
          element={
            <PublicRoute>
              <RegistrationForm />
            </PublicRoute>
          }
        />
        <Route
          path="/get-help/get-help-form"
          element={
            <PublicRoute>
              <V5GetHelpPage />
            </PublicRoute>
          }
        />
        {/* ṚEGISTRATION */}

        {/* Login */}
        <Route path="/login/login-form" element={<Login />} />
        {/* Login */}

        {/* Forgot Password */}
        <Route
          path="/forgot-password/generate-otp"
          element={
            <PublicRoute>
              <GenerateForgetPasswordOtp />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password/verify-otp"
          element={
            <PublicRoute>
              <VerifyForgotPasswordOtp />
            </PublicRoute>
          }
        />
        <Route
          path="/forgot-password/generate-new-password"
          element={
            <PublicRoute>
              <EnterNewPassword />
            </PublicRoute>
          }
        />
        {/* Forgot Password */}

        {/* Document flow */}
        <Route
          path="/document-flow/select-designation"
          element={
            <AuthRoute>
              <SelectDesignation />
            </AuthRoute>
          }
        />
        <Route
          path="/my-business-Edit-material/:MaterialTypeStperId"
          element={
            <AuthRoute>
              <PostEditMaterialPlaceStep />
            </AuthRoute>
          }
        />

        <Route
          path="/document-flow/upload-profile-picture"
          element={
            <AuthRoute>
              <UploadProfilePicture />
            </AuthRoute>
          }
        />
        <Route
          path="/document-flow/expertise"
          element={
            <AuthRoute>
              <SelectE9Role />
            </AuthRoute>
          }
        />
        <Route
          path="/document-flow/upload-equipment-skill"
          element={
            <AuthRoute>
              <UploadEquipmentSkill />
            </AuthRoute>
          }
        />
        <Route
          path="/document-flow/upload-verification-document"
          element={
            <AuthRoute>
              <UploadVerificationDocument />
            </AuthRoute>
          }
        />
        <Route
          path="/document-flow/upload-business-identification"
          element={
            <AuthRoute>
              <UploadBusinessIdentification />
            </AuthRoute>
          }
        />
        <Route
          path="/document-flow/upload-license"
          element={
            <AuthRoute>
              <UploadLicense />
            </AuthRoute>
          }
        />
        {/* Document flow */}

        <Route
          path="/company-member-details"
          element={
            <AuthRoute>
              <CompanyMemberDetails />
            </AuthRoute>
          }
        />

        {/* Dashboard Routes */}
        <Route
          path="/delete-account"
          element={
            <AuthRoute>
              <DeleteAccount />
            </AuthRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <AuthRoute>
              <Dashboard />
            </AuthRoute>
          }
        />
        <Route
          path="/my-profile"
          element={
            <AuthRoute>
              <MyProfile />
            </AuthRoute>
          }
        />
        <Route
          path="my-profile-details"
          element={
            <AuthRoute>
              <ProfileDetailsForm />
            </AuthRoute>
          }
        />
        <Route
          path="/verification-detail"
          element={
            <AuthRoute>
              <VerficationDetail />
            </AuthRoute>
          }
        />
        <Route
          path="/business-expertise"
          element={
            <AuthRoute>
              <BusinessDetail />
            </AuthRoute>
          }
        />
        <Route
          path="/job-status"
          element={
            <AuthRoute>
              <JobStatus />
            </AuthRoute>
          }
        />

        {/* Marketplace Routes */}
        <Route
          path="/marketplace/MarketPlaceRM"
          element={
            <AuthRoute>
              <RentalEquipmentMarketplace />
            </AuthRoute>
          }
        />
        <Route
          path={"/marketplace/MarketPlaceRM/Cards/:assetId"}
          element={
            <AuthRoute>
              <AssetDetailsPage />
            </AuthRoute>
          }
        />
        <Route
          path="/marketplace/MarketPlaceUEM"
          element={
            <AuthRoute>
              <UsedEquipmentMarketplace />
            </AuthRoute>
          }
        />
        <Route
          path={"/marketplace/MarketPlaceUEM/Cards/:assetId"}
          element={
            <AuthRoute>
              <AssetDetailsPage />
            </AuthRoute>
          }
        />

        <Route
          path={"/marketplace/MarketPlaceREM/Cards/:assetId"}
          element={
            <AuthRoute>
              <AssetDetailsPage />
            </AuthRoute>
          }
        />

        <Route
          path="/near-me/MarketPlaceOP"
          element={
            <AuthRoute>
              <OperatorNeaMeMarketplace />
            </AuthRoute>
          }
        />
        <Route
          path="/near-me/MarketPlaceOP/Card/:partnerId"
          element={
            <AuthRoute>
              <PartnerDetailsPage />
            </AuthRoute>
          }
        />
        <Route
          path="/marketplace/DealershipMarketplaceHome"
          element={
            <AuthRoute>
              <DealershipMarketplace />
            </AuthRoute>
          }
        />
        <Route
          path="/marketplace/DealershipMarketplaceHome/dealership-detail/:guid/:mpguid/:likeStatus/:taxNumber"
          element={
            <AuthRoute>
              <DealershipDetail />
            </AuthRoute>
          }
        />

        <Route
          path="/marketplace/MaterialMarketplace"
          element={
            <AuthRoute>
              <MaterialAssestPage />
            </AuthRoute>
          }
        />
        <Route
          path="/marketplace/MaterialMarketplace/material-details/:MaterialId/:OwnerUserCredentialId"
          element={
            <AuthRoute>
              <MaterialDetail />
            </AuthRoute>
          }
        />
        <Route
          path="/materials/spare-parts"
          element={
            <AuthRoute>
              <SparePartMaterialMarketplace />
            </AuthRoute>
          }
        />
        <Route
          path="/materials/oil"
          element={
            <AuthRoute>
              <OilMaterialMarketplace />
            </AuthRoute>
          }
        />
        <Route
          path="/materials/concrete"
          element={
            <AuthRoute>
              <ConcreteMaterialMarketplace />
            </AuthRoute>
          }
        />
        <Route
          path="/materials/generators"
          element={
            <AuthRoute>
              <GeneratorsMaterialMarketplace />
            </AuthRoute>
          }
        />
        <Route
          path="/materials/safety-equipment"
          element={
            <AuthRoute>
              <SafetyEquipmentMaterialMarketplace />
            </AuthRoute>
          }
        />

        <Route
          path="/near-me/MarketPlaceMP"
          element={
            <AuthRoute>
              <MaintenancePartnerNeaMeMarketplace />
            </AuthRoute>
          }
        />
        <Route
          path="/near-me/MarketPlaceMP/Card/:partnerId"
          element={
            <AuthRoute>
              <PartnerDetailsPage />
            </AuthRoute>
          }
        />

        <Route
          path="/upcoming/job-description/:upcomingJobId"
          element={
            <AuthRoute>
              <UpcomingJobStatus />
            </AuthRoute>
          }
        />
        <Route
          path="/upcoming/mp-job-description/:mpupcomingJobId"
          element={
            <AuthRoute>
              <Mpupcoming />
            </AuthRoute>
          }
        />
        <Route
          path="/ongoing/mp-job-description/:mpongoingJobId"
          element={
            <AuthRoute>
              <MpOngoing />
            </AuthRoute>
          }
        />
        <Route
          path="/completed/mp-job-description/:mpcompletedJobId"
          element={
            <AuthRoute>
              <MpCompleted />
            </AuthRoute>
          }
        />

       <Route
          path="/my-jobs/job-description/:JobId"
          element={
            <AuthRoute>
              <MyJobPostedDetailPage />
            </AuthRoute>
          }
        />

        {/* material starts*/}

        <Route
          path="/materials-type-store-list"
          element={
            <AuthRoute>
              <MaterialTypeStoreList />
            </AuthRoute>
          }
        />

        <Route
          path="/materials"
          element={
            <AuthRoute>
              <MyMaterialTypeStore />
            </AuthRoute>
          }
        />

        <Route
          path="/add-material/:materialTypeValue"
          element={
            <AuthRoute>
              <PostMaterialsSteps />
            </AuthRoute>
          }
        />

        <Route
          path="/material-details-page/:materialId"
          element={
            <AuthRoute>
              <MaterialDetailPage />
            </AuthRoute>
          }
        />

        {/* material end */}

        <Route
          path="/materials-store-list"
          element={
            <AuthRoute>
              <Materialstorelist />
            </AuthRoute>
          }
        />
        <Route
          path="/material/:MaterialTypeId"
          element={
            <AuthRoute>
              <MyBusinessStore />
            </AuthRoute>
          }
        />
        <Route
          path="/my-business-material-add-material-form-step-one/:MaterialTypeStperId"
          element={
            <AuthRoute>
              <PostMaterialSteps />
            </AuthRoute>
          }
        />
        {/* <Route path="/my-business-material-add-material-form-step-two/:MaterialId" element={<MaterialSteps2 />} /> */}
        <Route
          path="/create-Material-store"
          element={
            <AuthRoute>
              <MaterialStorePopup />
            </AuthRoute>
          }
        />
        <Route
          path="/jobs-near-me/AllJobsNearMeOP"
          element={
            <AuthRoute>
              <OperatorJobsNearMe />
            </AuthRoute>
          }
        />
        <Route
          path="/jobs-near-me/AllJobsNearMeOP/Card/:cardId"
          element={
            <AuthRoute>
              <JobDetailsPage />
            </AuthRoute>
          }
        />
        <Route
          path="/jobs-near-me/AllJobsNearMeMP"
          element={
            <AuthRoute>
              <MaintenanceJobsNearMe />
            </AuthRoute>
          }
        />
        <Route
          path="/jobs-near-me/AllJobsNearMeMP/Card/:cardId"
          element={
            <AuthRoute>
              <JobDetailsPage jobType="maintenance" />
            </AuthRoute>
          }
        />
        <Route
          path="/equipment-details"
          element={
            <AuthRoute>
              <EquipmentDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/operatorpatner-education-details"
          element={<OpEducationDetails />}
        />
        <Route
          path="/license-details"
          element={
            <AuthRoute>
              <LicenseDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/work-expirence"
          element={
            <AuthRoute>
              <WorkExpirence />
            </AuthRoute>
          }
        />
        <Route
          path="/edit-workexpirece"
          element={
            <AuthRoute>
              <WorkExpirence />
            </AuthRoute>
          }
        />
        <Route
          path="/company-profile"
          element={
            <AuthRoute>
              <BoCompanyProfile />
            </AuthRoute>
          }
        />
        <Route
          path="/subcription-details"
          element={
            <AuthRoute>
              <Subcription />
            </AuthRoute>
          }
        />
        <Route
          path="/raisesupport"
          element={
            <AuthRoute>
              <Equip9Support />
            </AuthRoute>
          }
        />

        <Route
          path="/subscription/subscription-type"
          element={
            <AuthRoute>
              <SubscriptionType />
            </AuthRoute>
          }
        />
        <Route
          path="/subscription-category/:subscriptionType"
          element={
            <AuthRoute>
              <SubscriptionServices />
            </AuthRoute>
          }
        />
        <Route
          path="/subscription/update-address"
          element={
            <AuthRoute>
              <UpdateAddress />
            </AuthRoute>
          }
        />
        <Route
          path="/subscription/review-subscription-details"
          element={
            <AuthRoute>
              <SubscriptionReview />
            </AuthRoute>
          }
        />
        <Route
          path="/subscription/payment-success"
          element={
            <AuthRoute>
              <PaymentSuccess />
            </AuthRoute>
          }
        />
        <Route
          path="/subscription/purchased-subscriptions"
          element={
            <AuthRoute>
              <PurchasedSubscriptions />
            </AuthRoute>
          }
        />
        <Route
          path="/subscription/my-free-contacts"
          element={
            <AuthRoute>
              <MyFreeContact />
            </AuthRoute>
          }
        />
        <Route
          path="/transactions/subscription"
          element={
            <AuthRoute>
              <TransactionHistory />
            </AuthRoute>
          }
        />

        {/* My Buisiness */}
        <Route
          path="/user-equipment-page/:assetId"
          element={
            <AuthRoute>
              <UserEquipmentPage />
            </AuthRoute>
          }
        />

        <Route
          path="/my-business"
          element={
            <AuthRoute>
              <MyBusinessAsset />
            </AuthRoute>
          }
        />
        <Route
          path="/my-business-dealerships-listing/:serviceTypeId"
          element={
            <AuthRoute>
              <MyBusinessDealershipListing />
            </AuthRoute>
          }
        />
        <Route
          path="/my-business/your-dealerships/:serviceTypeId"
          element={
            <AuthRoute>
              <PostDealershipSteps />
            </AuthRoute>
          }
        />

        {/* Legal Policy Routes */}
        <Route
          path="/legal-policy/about-us"
          element={
            <AuthRoute>
              <AboutUs />
            </AuthRoute>
          }
        />
        <Route
          path="/legal-policy/terms-and-condition"
          element={
            <AuthRoute>
              <TermsAndCondition />
            </AuthRoute>
          }
        />
        <Route
          path="/legal-policy/contact-us"
          element={
            <AuthRoute>
              <ContactUs />
            </AuthRoute>
          }
        />
        <Route
          path="/legal-policy/privacy-policy"
          element={
            <AuthRoute>
              <LegalPrivacyPolicy />
            </AuthRoute>
          }
        />
        {/* <Route path="/our-product"  element={<OurProduct/>} /> */}

        {/* Settings Routes */}
        <Route
          path="/settings"
          element={
            <AuthRoute>
              <Settings />
            </AuthRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <AuthRoute>
              <ChangePassword />
            </AuthRoute>
          }
        />
        <Route
          path="/privacy-settings"
          element={
            <AuthRoute>
              <PrivacySettings />
            </AuthRoute>
          }
        />
        <Route
          path="/delete-account"
          element={
            <AuthRoute>
              <DeleteAccount />
            </AuthRoute>
          }
        />
        {/* E9Srvices */}
        <Route
          path="/e9-services"
          element={
            <AuthRoute>
              <E9Services />
            </AuthRoute>
          }
        />
        <Route
          path="/e9-services/operator-search/job-post/:nickname/:eqTypeId/:eqMakeId/:eqModelId"
          element={
            <AuthRoute>
              <OperatorJobPost />
            </AuthRoute>
          }
        />
        {/* <Route path = "/experiment" element={  <AuthRoute><MaintenanceJobPost/>}/> */}
        <Route
          path="/e9-services/maintenance-search/job-post/:nickname/:eqTypeId/:eqMakeId/:eqModelId"
          element={
            <AuthRoute>
              <MaintenanceJobPost />
            </AuthRoute>
          }
        />
        {/* <Route path = "/e9-services/equipment-listing" element = {  <AuthRoute><EquipmentListingForm/></AuthRoute>}/> */}

        {/* Finances */}
        {/* <Route path="/my-business-finance-listing" element={  <AuthRoute><MyBusinessFinanceListing/></AuthRoute>} />
          <Route path="/my-finance-step-one" element={  <AuthRoute><AddFinanceStepOne/></AuthRoute>} /> 
         <Route path ="/my-finance-step-two" element={  <AuthRoute><AddFinanceStepTwo/></AuthRoute>}/>
          <Route path="/my-finance-step-three" element={  <AuthRoute><AddFinanceStepThree/></AuthRoute>} />

          <Route path="/my-finance-detail-page" element={  <AuthRoute><FinanceDetailPage/></AuthRoute>} /> */}

        {/* Settings Routes */}
        <Route
          path="/settings"
          element={
            <AuthRoute>
              <Settings />
            </AuthRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <AuthRoute>
              <ChangePassword />
            </AuthRoute>
          }
        />
        <Route
          path="/privacy-settings"
          element={
            <AuthRoute>
              <PrivacySettings />
            </AuthRoute>
          }
        />
        <Route
          path="/delete-account"
          element={
            <AuthRoute>
              <DeleteAccount />
            </AuthRoute>
          }
        />
        <Route
          path="/change-language"
          element={
            <AuthRoute>
              <ChangeLanguage />
            </AuthRoute>
          }
        />
        {/* E9Srvices */}
        <Route
          path="/e9-services"
          element={
            <AuthRoute>
              <E9Services />
            </AuthRoute>
          }
        />
        <Route
          path="/e9-services/operator-search/job-post"
          element={
            <AuthRoute>
              <OperatorJobPost />
            </AuthRoute>
          }
        />
        <Route
          path="/e9-services/maintenance-search/job-post"
          element={
            <AuthRoute>
              <MaintenanceJobPost />
            </AuthRoute>
          }
        />
        <Route
          path="/e9-services/equipment-listing"
          element={
            <AuthRoute>
              <EquipmentListingForm />
            </AuthRoute>
          }
        />

        <Route
          path="/my-jobs"
          element={
            <AuthRoute>
              <MyJobs />
            </AuthRoute>
          }
        />
        <Route
          path="/equipment-store"
          element={
            <AuthRoute>
              <EquipmentStore />
            </AuthRoute>
          }
        />
        {/* <Route
          path="/equipment-store?marketplace=rental"
          element={
            <AuthRoute>
              <EquipmentStore />
            </AuthRoute>
          }
        />
        <Route
          path="/equipment-store?marketplace=used"
          element={
            <AuthRoute>
              <EquipmentStore />
            </AuthRoute>
          }
        /> */}
        <Route
          path="/my-store/additional-details"
          element={
            <AuthRoute>
              <AdditionalEquipmentDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/my-store/create-marketplace-details"
          element={
            <AuthRoute>
              <CreateE9MarketplaceDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/e9-marketplace"
          element={
            <AuthRoute>
              <AllMarketplaces />
            </AuthRoute>
          }
        />
        <Route
          path="/partners-nearby"
          element={
            <AuthRoute>
              <OwnerMarketplace />
            </AuthRoute>
          }
        />

        {/* My Leads */}
        <Route
          path="/my-leads"
          element={
            <AuthRoute>
              <MyLeads />
            </AuthRoute>
          }
        />
        <Route
          path="/leads"
          element={
            <AuthRoute>
              <TabContainer />
            </AuthRoute>
          }
        />
        <Route
          path="/industry-requirement-leads"
          element={
            <AuthRoute>
              <IndustryLeads />
            </AuthRoute>
          }
        />
        <Route
          path="/owners-leads"
          element={
            <AuthRoute>
              <OwnersLeads />
            </AuthRoute>
          }
        />
        <Route
          path="/my-leads/accept-reject/:marketplaceId"
          element={
            <AuthRoute>
              <AcceptReject />
            </AuthRoute>
          }
        />
        <Route
          path="my-leads/dealership/:dealershipId/:dealerName/:dealershipUniqueId"
          element={
            <AuthRoute>
              <DealershipConnectCard />
            </AuthRoute>
          }
        />

        {/* start Open Deferred Marketplace */}
        <Route
          path={"/deferred/marketplace/Cards/:category/:subcategory/:assetId"}
          element={
            <PublicRoute>
              <OpenEquipmentMarketplace />
            </PublicRoute>
          }
        />

        <Route
          path={"/deferred/marketplace/partners/Cards/:category/:assetId"}
          element={
            <PublicRoute>
              <OpenPartnersDetails />
            </PublicRoute>
          }
        />

        <Route
          path={"/deferred/marketplace/Job/:category/:jobId"}
          element={
            <PublicRoute>
              <OpenJobDetailPage />
            </PublicRoute>
          }
        />

        <Route
          path="/owner-marketplace-details/:OwnerUserCredentialId/:OwnerTaxNumber"
          element={
            <AuthRoute>
              <OwnerMarketplaceDetails />
            </AuthRoute>
          }
        />

        {/* Premium */}
        <Route
          path={"/subscription/buy-premium"}
          element={
            <AuthRoute>
              <PremiumBenefits />
            </AuthRoute>
          }
        />
        <Route
          path={"/subscription/premium-checkout"}
          element={
            <AuthRoute>
              <PremiumCheckout />
            </AuthRoute>
          }
        />
        <Route
          path={"/subscription/my-premium"}
          element={
            <AuthRoute>
              <MyPremium />
            </AuthRoute>
          }
        />

        {/* End Open Deferred Marketplace */}
        {/* Community start */}
        <Route
          path="/community"
          element={
            <AuthRoute>
              <CommunityPage />
            </AuthRoute>
          }
        />

        <Route
          path="/community/my-content"
          element={
            <AuthRoute>
              <MyContentPage />
            </AuthRoute>
          }
        />

        <Route
          path="/community/:action/:id?"
          element={
            <AuthRoute>
              <CommunityForm />
            </AuthRoute>
          }
        />

        {/* community end */}

        {/* End Open Deferred Marketplace */}

        {/* Industry Requirements */}
        <Route
          path="/industry-requirement"
          element={<IndustryRequirementListing />}
        />

        <Route path="/my-requirement" element={<MyRequirement />} />
        <Route path="/requirement-category" element={<RequirementCategory />} />
        <Route path="/post-requirement" element={<PostYourRequirement />} />
        <Route
          path="/industry-requirement-leads/request-details/:requestId/:serviceTypeId"
          element={
            <AuthRoute>
              <IndustryRequestDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/industry-requirement-leads/likes-details/:likeId"
          element={
            <AuthRoute>
              <IndustryLikePage />
            </AuthRoute>
          }
        />
        <Route
          path="premium/payment-status"
          element={
            <AuthRoute>
              <PremiumPaymentStatus />
            </AuthRoute>
          }
        />

        {/* quiz start */}
        <Route
          path="/e9-quizzes"
          element={
            <AuthRoute>
              <QuizListing />
            </AuthRoute>
          }
        />

        <Route
          path="/quiz-session"
          element={
            <AuthRoute>
              <QuizPage />
            </AuthRoute>
          }
        />

        <Route
          path="/quiz/terms-and-conditions"
          element={
            <AuthRoute>
              <QuizTermsAndConditions />
            </AuthRoute>
          }
        />

        <Route
          path="/quiz/:quizId/winners"
          element={
            <AuthRoute>
              <QuizWinnersPage />
            </AuthRoute>
          }
        />

        
        <Route
          path="/my-quizzes"
          element={
            <AuthRoute>
              <MyQuizzesPage />
            </AuthRoute>
          }
        />

        {/* quiz end */}

        <Route
          path="job-cycle"
          element={
            <AuthRoute>
              <JobCycle />
            </AuthRoute>
          }
        />

        <Route
          path="/job-cycle/partner-profile/:partnerId"
          element={
            <AuthRoute>
              <PartnerDetailsPage />
            </AuthRoute>
          }
        />
        {/* <Route path="my-jobs" element={<AuthRoute>
         
        </AuthRoute>} /> */}

        <Route path="*" element={<Warning />} />
      </SentryRoutes>
    </ChakraProvider>
  );
}

export default App;
